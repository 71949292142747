<template>
  <div style="min-height: 750px;">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['support_details'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row style="font-size: 14px;">
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <!--
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="4">
              {{ d_userSupportWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}
            </b-col>
            <b-col sm="12" lg="8">
              <select class="form-control" v-model="d_userSupportData.status">
                <option v-for="(op, op_index) in d_userSupportWdm['0']['parameters']['0'].options" :value="op.value">
                  {{ op.translation[StoreLang] }}
                </option>
              </select>
            </b-col>
          </b-row>
        -->

          <b-row>
            <b-col cols="4">
              <strong> <i class="fa fa-check-square-o"></i> </strong>
              <b> {{ StoreLangTranslation.data['support_operation_name'][StoreLang] }} </b>
            </b-col>
            <b-col cols="8">
            </b-col>
          </b-row>
          <b-row style="padding: 10px;">
            <b-col cols="12">
              {{ d_userSupportData.operation_name }}
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="4">
              <strong> <i class="fa fa-check-square-o"></i> </strong>
              <b> {{ StoreLangTranslation.data['support_operation_note'][StoreLang] }} </b>
            </b-col>
            <b-col cols="8">
            </b-col>
          </b-row>
          <b-row style="padding: 10px;">
            <b-col cols="12">
              {{ d_userSupportData.operation_note }}
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="4">
              <strong> <i class="fa fa-check-square-o"></i> </strong>
              <b> {{ StoreLangTranslation.data['comments'][StoreLang] }} </b>
            </b-col>
            <b-col cols="8">
            </b-col>
          </b-row>

        <template v-if="d_worganizationOperationData.data && d_worganizationOperationData.data.general && d_worganizationOperationData.data.general.comment && d_worganizationOperationData.data.general.comment.list && d_worganizationOperationData.data.general.comment.list.length > 0">
          <template v-for="(com, com_ind) in d_worganizationOperationData.data.general.comment.list">
            <b-card :key="'d_worganizationOperationData.data.general.comment.list_' + com_ind" no-body :style="com_ind % 2 === 0 ? 'margin: 3px; background-color: lightyellow;' : 'margin: 3px; background-color: ghostwhite;'">
              <b-row>
                <b-col sm="12" md="10">
                  <img src="@/icon/9370.png" style="border-radius: 3px; width: 20px;" />
                  <template v-if="com.customer_name && com.customer_name.val">
                    {{ com.customer_name && com.customer_name.val ? com.customer_name.val : '' }}
                  </template>
                  <template v-else>
                    {{ com.who_commented.val.label }} 
                  </template>
                </b-col>
                <b-col sm="12" md="2">
                  {{ f_dateFormat(com.comment_datetime.val, 'year-month-day hh:mm') }}
                </b-col>
              </b-row>
              <b-row style="padding: 10px; margin: 0px;">
                <b-col sm="12" md="12">
                  <div v-html="com.message.val"> </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </template>
        <b-row v-if="d_userSupportData.status === '1'" style="margin-top: 20px; padding: 5px; ">
            <b-col cols="12">
              <b-form-textarea v-model="d_commentNew" rows="5" :placeholder="'maksimum 500 karakter'"></b-form-textarea>
            </b-col>
          </b-row>
        <b-row v-if="d_userSupportData.status === '1'" style="margin: 3px;">
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
            <b-button variant="white" @click="f_userSupportComment()">
              <i class="fa fa-plus-circle"></i>
              {{ StoreLangTranslation.data['comment_add'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import UserSupportService from '@/services/support';
import { data as usr_support } from '@/options/usr_support';
import moment from "moment";
import { mapGetters } from 'vuex';
export default {
  name: 'user_support_details',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
  }),
  components: {},
  props: {},
  data () {
    return {
      d_commentNew: '',
      d_worganizationOperationData: '',
      d_user: '',
      d_frontendClientID: '',
      d_userSupportClientID: '',
      d_frontendList: [],
      d_userSupportWdm: usr_support,
      d_userSupportData: {
        'status': '',
        'organization_id': '',
        'operation_id': '',
        'subject': '',
        'operation_update_date': '',
        'shop_bundle_client_id': '',
        'shop_bundle_product_client_id': '',
        'operation_name': '',
        'operation_note': '',
        'shop_client_id': '',
        'created_by': '',
        'created_at': '',
        'cancelled_by': '',
        'cancel_date': '',
      }
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
     // this.f_langEligibleParameterProcess();
  },
  beforeMount () {},
  mounted: function () {
    if (this.$route.query.user_support_client_id) {
      this.d_userSupportClientID = this.$route.query.user_support_client_id;
      this.f_userSupportGet();
    }
    // console.log(this.StoreLangList);
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_userSupportWdm);
  },
  methods: {
    f_dateFormat: function (date, date_type='year-month-day') {
      if (date) {
        if (date_type === 'year-month-day') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD");
            return x;
          }
        } else if (date_type === 'year-month-day hh:mm') {
          let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY HH:MM");
          return x;
        }
      } else {
        return "";
      }
    },
    f_langEligibleParameterProcess: function () {
      let lang_eligible_variables = [];
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          if (!this.d_userSupportData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label]) {
            this.d_userSupportData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
          }
        }
      }
    },
    f_userShopRecord: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      if (this.d_userSupportClientID) {
        query += '&user_support_client_id=' + this.d_userSupportClientID;
      }
      for (let i in this.d_userSupportData) {
        data[i] = this.d_userSupportData[i];
      }
      if (this.d_userSupportClientID) {
        UserSupportService.user_shop_edit(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      } else {
        UserSupportService.user_shop_new(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          });
      }
    },
    f_userSupportGet: function () {
      let query = 'user_support_client_id=' + this.d_userSupportClientID;
      query += '&data_type=value_mode';
      let data = {};
      UserSupportService.user_support_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userSupportData = resp.data.data;
              this.d_worganizationOperationData = resp.data.worganization_operation;
              // this.f_langEligibleParameterProcess();
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_userSupportComment: function () {
      if (this.d_commentNew) {
        if (this.d_commentNew.length > 500) {
          alert(this.StoreLangTranslation.data['character_count_is_higher_than_expected'][this.StoreLang]);
        } else {
          let query = 'user_support_client_id=' + this.d_userSupportClientID;
          let data = {
            'comment': this.d_commentNew
          };
          UserSupportService.user_support_comment(query, data)
            .then(resp => {
              if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                this.f_userSupportGet();
              } else {
                alert(resp.data.status_code, ' ', resp.data.status_message);
              }
            });
        }
      } else {
        alert(this.StoreLangTranslation.data['please_write_your_comment'][this.StoreLang]);
      }
    },
  }
};

</script>

